export const DateUtil = {

    format(timestamp) {
        let date = new Date(timestamp);
        return date.toLocaleString('zh')
    },

    toDurationMinutesString(durationSeconds) {
        return parseInt(durationSeconds / 60)  + '分钟'
    },

    toDurationString(durationSeconds) {
        if (durationSeconds < 60) {
            return durationSeconds + '秒钟'
        }
        if (durationSeconds >= 60 && durationSeconds < 3600) {
            return parseInt(durationSeconds / 60)  + '分钟'
        }
        return parseInt(durationSeconds / 60 / 60)  + '小时'
    }

}